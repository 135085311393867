export const IS_PRD = process.env.NODE_ENV === 'production';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_STG = (process.env.NODE_ENV as any) === 'staging';
// TODO ポート番号　別で定義
export const BASE_URL = IS_DEV ? 'http://localhost:3100' : process.env.REACT_APP_BASE_URL ?? '';
export const REACT_APP_TEACHER_URL = IS_DEV
  ? 'http://localhost:' + process.env.REACT_APP_TEACHER_PORT
  : process.env.REACT_APP_TEACHER_URL ?? '';
export const REACT_APP_API_BASE_URL = IS_DEV
  ? `http://localhost:${process.env.REACT_APP_SERVER_APP_PORT}`
  : process.env.REACT_APP_API_BASE_URL;
export const REACT_APP_ONLINE_LESSON_TEACHER_URL = IS_DEV
  ? 'http://localhost:5000'
  : process.env.REACT_APP_ONLINE_LESSON_TEACHER_URL ?? '';
export const REACT_APP_COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const REACT_APP_COGNITO_USER_POOL_ID =
  process.env.REACT_APP_COGNITO_USER_POOL_ID_FOR_TEACHER;
export const REACT_APP_COGNITO_USER_POOL_CLIENT_ID =
  process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID_FOR_TEACHER;
export const REACT_APP_CHAT_WEBSOCKET_URL = process.env.REACT_APP_CHAT_WEBSOCKET_URL ?? '';

export const COOKIE_DOMAIN = IS_DEV
  ? 'localhost'
  : process.env.REACT_APP_TEACHER_URL!.match(/(?<=https:\/\/).*/)![0];

export const URL_PRIVACY = process.env.REACT_APP_BASE_URL + '/privacy';
export const URL_TERMS = process.env.REACT_APP_BASE_URL + '/terms';
export const URL_TRANSACTIONS = process.env.REACT_APP_BASE_URL + '/transactions';

export const DELETE_FLG = {
  // 削除
  DELETED: '1',
  // 未削除
  AVAILABLE: '0',
} as const;
export type DELETE_FLG = typeof DELETE_FLG[keyof typeof DELETE_FLG];
export const STOP_FLG = {
  // 停止
  STOPPED: '1',
  // 未停止
  AVAILABLE: '0',
} as const;
export type STOP_FLG = typeof STOP_FLG[keyof typeof STOP_FLG];
// お知らせタイプ
export const NOTICE_TARGET = {
  // 共通
  COMMON_NOTICE: 0,
  // 企業
  COMPANY_NOTICE: 1,
  // 教師
  TEACHER_NOTICE: 2,
  // 学習者
  LEARNER_NOTICE: 3,
} as const;
export type NOTICE_TARGET = typeof NOTICE_TARGET[keyof typeof NOTICE_TARGET];
export const BILLED_FLG = {
  // 稼動
  DEFAULT: 0,
  // 請求送信済
  CONFIRMED: 1,
  // 請求確定
  APPROVED: 2,
  // 否認
  DENIED: 3,
} as const;
export type BILLED_FLG = typeof BILLED_FLG[keyof typeof BILLED_FLG];
export type QUERY_RESULT = number & { QUERY_RESULT: never };
export const MESSAGE = {
  NO_ERROR: 'NO_ERROR',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  NOT_AUTHORIZED_USER_ACCESS: 'NOT_AUTHORIZED_USER_ACCESS',
} as const;
export type MESSAGE = typeof MESSAGE[keyof typeof MESSAGE] | QUERY_RESULT;
export const MAX_EMAIL_CHARS = 3000;
// 承認フラグ
export const APPROVAL_FLG = {
  // 承認
  APPROVED: 1,
  // 承認待ち
  WAIT: 0,
} as const;
export type APPROVAL_FLG = typeof APPROVAL_FLG[keyof typeof APPROVAL_FLG];
// キャンセルフラグ
export const CANCEL_FLG = {
  // キャンセル済み
  CANCELED_MANAGER: 3,
  CANCELED_TEACHER: 2,
  CANCELED_COMPANY: 1,
  // キャンセルなし
  NONE: 0,
} as const;
export type CANCEL_FLG = typeof CANCEL_FLG[keyof typeof CANCEL_FLG];
//Total count for season, chapter, summary
export const TOTAL_COUNT = {
  /** シーン（旧CHAPTER） */
  SCENE: 10,
  /** エピソードまとめ */
  EPISODE_SUMMARY: 12,
  /** シーズンまとめ */
  SEASON_SUMMARY: 50,
};

export const LESSON_ROOM_ENTRY_BEFORE_TIME_MIN = 5;
export const S3_PATH = 'https://sun-resource-tokyo.s3-ap-northeast-1.amazonaws.com/';
export const KURASHI_STUDY_API_PATH = 'https://api-manager.kurashi-study.net/cms/';
// NOTE マジックナンバー対処用
export const STORY_EPISODE_ID_SECTION_INDEX = {
  'Ep.x-1': 0,
  'Ep.x-2': 1,
  'Ep.x-3': 2,
  'Ep.x summary': 3,
} as const;

export const FEED_BACK_STATUS = {
  COMPLETED: '記入済み',
  BLANK: '未記入',
} as const;
