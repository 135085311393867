import { useColorModeValue } from '@chakra-ui/color-mode';
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  AlertStatus,
} from '@chakra-ui/react';
import { Pagination } from 'evergreen-ui';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { serverApi, requestServerApi } from '../../api/api';
import '../../assets/styles/index.scss';
import { getLessonStatusBadge } from '../../components/BadgeStatus';
import { getFormattedDate, getFormattedTime } from '../../components/Format';
import useInterval from '../../hooks/useInterval/useInterval';
import useLessonByIdAndCompanyId from '../../hooks/useLesson/useLessonByIdAndCompanyId';
import useTwilioRoom from '../../hooks/useTwilioRoom/useTwilioRoom';
import './index.scss';
import { getIsNotAttendees, getIsRoomEntryEnabled } from '../../libs/lesson/lesson';
import { CANCEL_FLG } from '../../configs';
import useErrorPage from '../../hooks/useErrorPage/useErrorPage';
import ThemeModal from './Modal/ThemeModal/ThemeModal';
import ThemeEditModal from './Modal/ThemeEditModal/ThemeEditModal';
import FeedbackModal from './Modal/FeedbackModal/FeedbackModal';
import FeedbackEditModal from './Modal/FeedbackEditModal/FeedbackEditModal';
import FeedbackAlreadyExistModal from './Modal/FeedbackAlreadyExistModal/FeedbackAlreadyExistModal';
import BulkFeedbackUpdateModal from './Modal/BulkFeedbackUpdateModal/BulkFeedbackUpdateModal';
import NoteEditModal from './Modal/NoteEditModal/NoteEditModal';
import NoteModal from './Modal/NoteModal/NoteModal';
import AgreementLessonModal from './Modal/AgreementLessonModal/AgreementLessonModal';
import useAppToast from '../../hooks/useAppToast/useAppToast';

function Index() {
  const location = useLocation<any>();
  const lesson_id: string = useParams<any>().lessonId;
  const companyId = location?.state?.company_id ? location?.state?.company_id : '';

  const { displayToast } = useAppToast();
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const limit: number = 4;
  const [refreshFlg, setRefreshFlg] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [lessonNotFinished, setLessonNotFinished] = useState<boolean>(false);
  const [noteUpdating, setNoteUpdating] = useState<boolean>(false);
  const history = useHistory();
  const { lesson } = useLessonByIdAndCompanyId(lesson_id, companyId, refreshFlg);
  const { apiErrorHandler } = useErrorPage();
  const canceledLesson = [
    CANCEL_FLG.CANCELED_MANAGER,
    CANCEL_FLG.CANCELED_COMPANY,
    CANCEL_FLG.CANCELED_TEACHER,
  ];
  const { getRoomLink } = useTwilioRoom(lesson);
  const [isNoAttendees, setIsNoAttendees] = useState(false);
  useEffect(() => {
    setIsNoAttendees(getIsNotAttendees(lesson));
  }, [lesson]);
  // 1秒ごとに確認
  useInterval(() => {
    if (lesson === null) {
      goBackTop();
      return;
    }
    checkLessonEnter();
  }, 1000 * 1);

  const goBackTop = () => history.push('/top');
  const checkLessonEnter = useCallback(() => {
    setDisable(
      !getIsRoomEntryEnabled(
        lesson?.start_datetime,
        lesson?.end_datetime,
        lesson?.approval_flg,
        lesson?.cancel_flg,
      ),
    );
  }, [lesson]);

  useEffect(() => {
    if (lesson === null) {
      goBackTop();
      return;
    }

    setTotalPages(
      Math.ceil(
        lesson?.t_lesson_learner?.length >= limit ? lesson?.t_lesson_learner?.length / limit : 1,
      ),
    );
    hasLessonFinished(lesson?.end_datetime);
    checkLessonEnter();
  }, [lesson, disable]);

  const handlePageChange = (targetPage: number) => {
    setCurrentPage(targetPage);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage: number) => prevPage - 1);
    return;
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage: number) => prevPage + 1);
    return;
  };

  useEffect(() => {
    setRefreshFlg(false);
  }, [refreshFlg == true]);

  // Modal切り分け用
  const [selectedModal, setSelectedModal] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<any>('');

  // モーダルを開く
  const onOpenModal = (name: string, item: any) => {
    setSelectedModal(name);
    setSelectedItem(item);
  };

  // モーダルを閉じる
  const onCloseModal = () => {
    setSelectedModal('');
    setConfirming(false);
  };

  // 編集入力
  const handleInputChange = (e: any) => {
    const value = e?.target?.value;
    if (value) {
      selectedItem.feed_back = value;
      selectedItem['note'] = value;
      selectedItem['theme'] = value;
      setSelectedItem(selectedItem);
      setConfirming(selectedItem.feed_back.length === 0);
    }
  };

  const onCheckFeedBackExist = () => {
    setConfirming(true);
    const apiResponse = requestServerApi<any>(() =>
      serverApi.organizationsControllerGetLessonFeedbackList(
        '',
        0,
        '',
        lesson.lesson_id,
        '',
        '',
        '',
        0,
        0,
        0,
      ),
    );
    apiResponse.then((result) => {
      if (result?.data?.data?.length > 0) {
        let apiData = result.data.data[0][0];
        let feedbackExists = apiData.some((feedbackList: any) => feedbackList.feed_back);
        if (feedbackExists) {
          onOpenModal('feedbackAlreadyExistModal', lesson);
        } else {
          onOpenModal('bulkFeedbackUpdateModal', lesson);
        }
      }
    });
  };

  const updateBulkFeedback = () => {
    setConfirming(true);
    const apiResponse = requestServerApi<any>(() =>
      serverApi.organizationsControllerUpdateLessonFeedback(
        lesson.company_id,
        lesson.lesson_id,
        '',
        selectedItem?.feed_back,
      ),
    );
    apiResponse.then((result) => {
      reactToUpdation(result.data.message, 'フィードバック更新');
      onCloseModal();
      setRefreshFlg(true);
      setConfirming(false);
    });
  };

  const updateTheme = () => {
    const apiResponse = requestServerApi<any>(() =>
      serverApi.organizationsControllerUpdateThemeOnLesson(
        lesson.company_id,
        lesson.lesson_id,
        selectedItem?.theme,
      ),
    );
    apiResponse.then((result) => {
      reactToUpdation(result.data.message, 'テーマの更新');
      setRefreshFlg(true);
      setConfirming(false);
    });
  };
  // 申し送り更新処理
  const onNoteUpdate = async () => {
    setNoteUpdating(true);
    const apiResponse = requestServerApi<any>(() =>
      serverApi
        .organizationsControllerUpdateLessonNote(
          String(selectedItem.company_id),
          selectedItem.lesson_id,
          selectedItem.note,
        )
        .catch((e) =>
          apiErrorHandler(e, {
            componentName: 'lesson',
            functionName: serverApi.organizationsControllerUpdateLessonNote.name,
          }),
        ),
    );
    apiResponse.then((result) => {
      let apiData = result.data;
      if (apiData) {
        reactToUpdation(apiData.message, '申し送り事項更新');
        onCloseModal();
        setRefreshFlg(true);
        setNoteUpdating(false);
      }
    });
  };

  //update learner's feedback
  const updateFeedBack = async () => {
    const apiResponse = requestServerApi<any>(() =>
      serverApi
        .organizationsControllerUpdateLessonFeedback(
          selectedItem.company_id,
          selectedItem.lesson_id,
          selectedItem?.learner_id,
          {
            feedback: selectedItem?.feed_back,
          },
        )
        .catch((e) =>
          apiErrorHandler(e, {
            componentName: 'lesson',
            functionName: serverApi.organizationsControllerUpdateLessonFeedback.name,
          }),
        ),
    );
    apiResponse.then((data) => {
      if (data?.data?.message) {
        reactToUpdation(data.data.message, 'フィードバック更新');
      }
      onCloseModal();
      setRefreshFlg(true);
    });
  };

  const onClickLearnerDetail = (learner_id: string) => {
    // history.push(`/${lesson_id}/learners/${learner_id}`, { companyId });
    window.open(`/lesson/${companyId}/${lesson_id}/learners/${learner_id}`);
  };

  //disable feedback button if lesson has not finished yet
  const hasLessonFinished = (end_datetime: Date) => {
    const current_time: Date = new Date();
    const lesson_end_time: Date = new Date(end_datetime);
    if (current_time <= lesson_end_time) {
      setLessonNotFinished(true);
    } else {
      setLessonNotFinished(false);
    }
  };

  //update the lesson status
  const updateLessonStatus = async (e: any) => {
    var status = e.status;
    const apiResponse = requestServerApi<any>(() =>
      serverApi
        .organizationsControllerUpdateLessonStatusTeacher(lesson?.company_id, lesson_id, status)
        .catch((e) =>
          apiErrorHandler(e, {
            componentName: 'lesson',
            functionName: serverApi.organizationsControllerUpdateLessonStatusTeacher.name,
          }),
        ),
    );
    apiResponse.then((data) => {
      if (data?.data.message) {
        reactToUpdation(
          data.data.message,
          status === 'approved' ? 'レッスン承認' : 'レッスン予約キャンセル',
        );
      }
      setRefreshFlg(true);
    });
  };

  //　更新後の処理
  const reactToUpdation = (msg: string, toastTitle: string) => {
    var toastDescription: string =
      msg === 'NO_ERROR' ? `${toastTitle}に成功しました。` : `${toastTitle}に失敗しました。`;
    var toastStatus: AlertStatus = msg === 'NO_ERROR' ? 'success' : 'error';
    setTimeout(() => {
      onCloseModal();
      displayToast(toastTitle, toastDescription, toastStatus);
    }, 1000);
  };
  return (
    <>
      {'themeModal' === selectedModal && (
        <ThemeModal onOpenModal={onOpenModal} onCloseModal={onCloseModal} lesson={lesson} />
      )}
      {'themeEditModal' === selectedModal && (
        <ThemeEditModal
          onCloseModal={onCloseModal}
          updateTheme={updateTheme}
          handleInputChange={handleInputChange}
          lesson={lesson}
        />
      )}
      {'feedbackModal' === selectedModal && (
        <FeedbackModal
          selectedItem={selectedItem}
          lesson={lesson}
          onClose={onCloseModal}
          onOpenModal={onOpenModal}
        />
      )}
      {'feedbackEditModal' === selectedModal && (
        <FeedbackEditModal
          selectedItem={selectedItem}
          onClose={onCloseModal}
          handleInputChange={handleInputChange}
          updateFeedBack={updateFeedBack}
        />
      )}
      {'feedbackAlreadyExistModal' === selectedModal && (
        <FeedbackAlreadyExistModal
          lesson={lesson}
          onClose={onCloseModal}
          onOpenModal={onOpenModal}
        />
      )}
      {'bulkFeedbackUpdateModal' === selectedModal && (
        <BulkFeedbackUpdateModal
          onClose={onCloseModal}
          handleInputChange={handleInputChange}
          updateBulkFeedback={updateBulkFeedback}
          confirming={confirming}
          selectedItem={selectedItem}
        />
      )}
      {'noteModal' === selectedModal && (
        <NoteModal onClose={onCloseModal} onOpenModal={onOpenModal} selectedItem={selectedItem} />
      )}
      {'noteEditModal' === selectedModal && (
        <NoteEditModal
          onClose={onCloseModal}
          onNoteUpdate={onNoteUpdate}
          handleInputChange={handleInputChange}
          selectedItem={selectedItem}
          noteUpdating={noteUpdating}
        />
      )}
      {'agreementLessonModal' === selectedModal && (
        <AgreementLessonModal onClose={onCloseModal} updateLessonStatus={updateLessonStatus} />
      )}
      {/* 画面描画エリア */}
      <Flex justifyContent="space-between" alignItems="center">
        <Heading mt={5}>レッスン詳細</Heading>
      </Flex>
      <br></br>
      <Stat
        px={'10'}
        py={'10'}
        borderColor={useColorModeValue('#ddd', '#ddd')}
        rounded={'md'}
        className="Lesson-Stat"
      >
        <Flex>
          <Box w="14em">
            <StatLabel fontWeight={'bold'} isTruncated whiteSpace={'pre-wrap'}>
              ID
            </StatLabel>
            <StatNumber fontSize={'lg'} fontWeight={'light'}>
              {lesson_id}
            </StatNumber>
          </Box>
          <Box w="12em">
            <StatLabel fontWeight={'bold'} isTruncated justifyContent="center">
              ステータス
            </StatLabel>
            <StatNumber fontWeight={'bold'}>{getLessonStatusBadge(lesson)}</StatNumber>
          </Box>
          <Box w="50em">
            <StatLabel fontWeight={'bold'} isTruncated whiteSpace={'pre-wrap'}>
              レッスン操作
            </StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'light'}>
              <Flex>
                <Button
                  mr={5}
                  colorScheme="blue"
                  size="sm"
                  onClick={() => onOpenModal('agreementLessonModal', '')}
                  disabled={lesson?.approval_flg == 1 || lesson?.cancel_flg != 0}
                >
                  承認
                </Button>
              </Flex>
            </StatNumber>
          </Box>
        </Flex>
        <Flex>
          <Box w="14em">
            <StatLabel fontWeight={'bold'} isTruncated whiteSpace={'pre-wrap'}>
              実施日時
            </StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'light'}>
              {getFormattedDate(lesson?.start_datetime)}
            </StatNumber>
          </Box>
          <Box w="12em">
            <StatLabel fontWeight={'bold'} isTruncated whiteSpace={'pre-wrap'}></StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'light'} paddingTop="1.3rem">
              {getFormattedTime(lesson?.start_datetime)} ~ {getFormattedTime(lesson?.end_datetime)}
            </StatNumber>
          </Box>
          <Box w="50em">
            <StatNumber fontSize={'2xl'} fontWeight={'light'}>
              <Link href={getRoomLink()} isExternal style={{ textDecoration: 'none' }}>
                <Button
                  mr={5}
                  colorScheme="teal"
                  size="sm"
                  disabled={!lesson || disable || isNoAttendees}
                >
                  入室
                </Button>
              </Link>
            </StatNumber>
            <StatLabel pl="10px" fontWeight={'bold'} isTruncated whiteSpace={'pre-wrap'}>
              開始5分前から入室可能
            </StatLabel>
          </Box>
        </Flex>
        <Flex>
          <Box w="30em">
            <StatLabel fontWeight={'bold'} isTruncated whiteSpace={'pre-wrap'}>
              企業名
            </StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'light'}>
              {lesson?.m_company.company_name}
            </StatNumber>
          </Box>
          <Box marginLeft={60} mt={4}>
            <StatNumber fontSize={'2xl'} fontWeight={'light'}>
              <Box mb={2}>
                <Button
                  colorScheme="linkedin"
                  className="tealButton"
                  onClick={() => onOpenModal('noteModal', lesson)}
                  disabled={
                    !lesson || lessonNotFinished || canceledLesson.includes(lesson?.cancel_flg)
                  }
                >
                  申し送り登録
                </Button>
              </Box>
              <Box mb={2}>
                <Button
                  colorScheme="linkedin"
                  style={{ width: '13rem' }}
                  disabled={
                    !lesson || lessonNotFinished || canceledLesson.includes(lesson?.cancel_flg)
                  }
                  onClick={() => onCheckFeedBackExist()}
                >
                  フィードバック一括登録
                </Button>
              </Box>
              <Box>
                <Button
                  colorScheme="linkedin"
                  style={{ width: '13rem' }}
                  disabled={
                    !lesson || lessonNotFinished || canceledLesson.includes(lesson?.cancel_flg)
                  }
                  onClick={() => onOpenModal('themeModal', lesson)}
                >
                  テーマ登録
                </Button>
              </Box>
            </StatNumber>
          </Box>
        </Flex>
        <Flex>
          <Spacer />
        </Flex>
        <Flex></Flex>
      </Stat>
      <Box className="learner-DataCountArea"></Box>{' '}
      <Box className="Lesson-Table">
        <Heading size="lg" pt="2rem" pb="1rem">
          参加学習者一覧
        </Heading>
        <Table variant="simple" height="200">
          <Thead className="Lesson-TableHead">
            <Tr>
              <Th></Th>
              <Th>出欠席</Th>
              <Th>名前</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody className="Lesson-TableBody">
            {lesson?.t_lesson_learner?.map((learner: any, idx: number) => (
              <Tr key={idx}>
                <Td>
                  <Button
                    colorScheme="linkedin"
                    size="sm"
                    onClick={() => onClickLearnerDetail(learner.learner_id)}
                  >
                    詳細
                  </Button>
                </Td>
                <Td>
                  {learner?.delete_flg == false ? (
                    <Badge
                      colorScheme="green"
                      style={{
                        lineHeight: '1.75rem',
                        width: '4rem',
                        textAlign: 'center',
                      }}
                      fontSize={'md'}
                    >
                      出席
                    </Badge>
                  ) : (
                    <Badge
                      colorScheme="red"
                      style={{
                        lineHeight: '1.75rem',
                        width: '4rem',
                        textAlign: 'center',
                      }}
                      fontSize={'md'}
                    >
                      欠席
                    </Badge>
                  )}
                </Td>
                <Td whiteSpace="nowrap">{learner.m_learner.nick_name}</Td>
                <Td>
                  <Button
                    colorScheme="linkedin"
                    size="sm"
                    onClick={() => onOpenModal('feedbackModal', learner)}
                    disabled={!lesson || lessonNotFinished}
                  >
                    フィードバック登録
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Box className="Lesson-PaginationArea">
          <Pagination
            page={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onNextPage={handleNextPage}
            onPreviousPage={handlePreviousPage}
          ></Pagination>
        </Box>
      </Box>
    </>
  );
}

export default Index;
