import { VStack, HStack, Box } from '@chakra-ui/react';
import React from 'react';
import { FieldNames } from '../../..';
import { getHoursAndMinutes } from '../../../../../components/Format';

type Props = {
  lessonRecord: any;
};
const Plan = ({ lessonRecord }: Props) => {
  return (
    <>
      <VStack
        align="left"
        padding={'1'}
        justifyContent={'space-between'}
        m="10px"
        border={'2px solid Black'}
        key={Math.random()}
      >
        <VStack padding={'1'} m="5px" align="left" justifyContent={'space-between'}>
          <HStack align={'center'}>
            <Box fontSize={'md'} p={'0.5'} fontWeight="bold">
              プラン:
            </Box>
            <Box fontSize={'md'} p={'0.5'} fontWeight="bold">
              {lessonRecord?.plan_name}
            </Box>
          </HStack>
          <HStack paddingY="1" align="left">
            <Box fontSize={'md'} p={'0.5'} width="220px">
              {FieldNames.Talk_060}:
            </Box>
            <Box fontSize={'md'} p={'0.5'}>
              {lessonRecord
                ? parseInt(lessonRecord?.attendance) + parseInt(lessonRecord?.absence)
                : '0'}
              回
            </Box>
          </HStack>
          <HStack paddingY="1" align="left">
            <Box fontSize={'md'} p={'0.5'} width="220px">
              {FieldNames.Talk_020}:
            </Box>
            <Box fontSize={'md'} p={'0.5'}>
              {lessonRecord ? lessonRecord?.attendance : '0'}回
            </Box>
          </HStack>
          <HStack paddingY="1" align="left">
            <Box fontSize={'md'} p={'0.5'} width="220px">
              {FieldNames.Talk_030}:
            </Box>
            <Box fontSize={'md'} p={'0.5'}>
              {lessonRecord ? lessonRecord?.absence : '0'}回
            </Box>
          </HStack>
          <HStack paddingY="1" align="left">
            <Box fontSize={'md'} p={'0.5'} width="220px">
              {FieldNames.Talk_040}:
            </Box>
            <Box fontSize={'md'} p={'0.5'}>
              {lessonRecord ? lessonRecord?.attendance_rate + '%' : '0%'}
            </Box>
          </HStack>
          <HStack paddingY="1" align="left">
            <Box fontSize={'md'} p={'0.5'} width="220px">
              {FieldNames.Talk_050}(hh:mm):
            </Box>
            <Box fontSize={'md'} p={'0.5'}>
              {lessonRecord?.total_lesson_time
                ? getHoursAndMinutes(lessonRecord?.total_lesson_time)
                : '00:00'}
            </Box>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};
export default Plan;
