import { AlertStatus, Grid, GridItem } from '@chakra-ui/react';
import {
  Stack,
  Text,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import './index.scss';
import '../../assets/styles/index.scss';
import { useLocation, useParams } from 'react-router-dom';
import { serverApi, requestServerApi } from '../../api/api';
import { getYearMonthFormattedDate } from '../../components/Format';

import { BILLED_FLG } from '../../configs';
import useErrorPage from '../../hooks/useErrorPage/useErrorPage';
import DeleteLessonOptionModal from './DeleteLessonOptionModal/DeleteLessonOptionModal';
import CreateLessonFeeOptionModal from './CreateLessonFeeOptionModal/CreateLessonFeeOptionModal';
import ConfirmedLessonFeeOptionModal from './ConfirmedLessonFeeOptionModal/ConfirmedLessonFeeOptionModal';
import useAppToast from '../../hooks/useAppToast/useAppToast';

function Index() {
  const tableRef = useRef<HTMLTableElement>(null);
  const location = useLocation<any>();
  const teacher_id = location?.state?.teacher_id ? location?.state?.teacher_id : '';
  const year_month: string = useParams<any>().yearMonth;
  const { displayToast } = useAppToast();
  const [refreshFlg, setRefreshFlg] = useState<any>([]);
  const [fees, setFee] = useState<any>([]);
  const [feeDetail, setFeeDetail] = useState<any>([]);
  const [buttonstate, setButtonstate] = useState(false);
  const current = new Date();
  const month =
    current.getMonth() + 1 < 10 ? '0' + `${current.getMonth() + 1}` : `${current.getMonth() + 1}`;
  const currentYearMonth = `${current.getFullYear()}${month}`;
  // Modal切り分け用
  const [selectedModal, setSelectedModal] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<any>('');
  const { apiErrorHandler } = useErrorPage();

  //set final fee
  const finalFee: any =
    fees?.lesson_couont * fees?.lesson_fee + fees?.total_option_fee
      ? Math.round(
          (fees.lesson_couont * fees.lesson_fee + fees?.total_option_fee) * (1 + fees.tax / 100),
        )
      : Math.round(fees?.lesson_couont * fees?.lesson_fee * (1 + fees?.tax / 100));

  //　更新後の処理
  const reactToUpdation = (msg: string, toastTitle: string) => {
    var toastDescription: string =
      msg === 'NO_ERROR' ? `${toastTitle}に成功しました。` : `${toastTitle}に失敗しました。`;
    var toastStatus: AlertStatus = msg === 'NO_ERROR' ? 'success' : 'error';
    setTimeout(() => {
      onCloseModal();
      displayToast(toastTitle, toastDescription, toastStatus);
    }, 1000);
  };

  const getTeacherLessonFee = () => {
    const response = requestServerApi<any>(() =>
      serverApi
        .organizationsControllerGetTeacherLessonFeeList(teacher_id, year_month, -1, 1, 1)
        .catch((e) =>
          apiErrorHandler(e, {
            componentName: 'fee',
            functionName: getTeacherLessonFee.name,
          }),
        ),
    );
    response.then((data) => {
      const apiResponse = data?.data;
      if (apiResponse.data && apiResponse.data != undefined) {
        setFee(apiResponse.data[0][0]);
        setFeeDetail(apiResponse.data[0][0]?.t_teacher_lesson_fee_detail);
        if (
          apiResponse?.data[0][0]?.billed_flg == 1 ||
          apiResponse?.data[0][0]?.billed_flg == 2 ||
          apiResponse?.data[0][0]?.year_month >= currentYearMonth
        ) {
          setButtonstate(true);
        }
      }
    });
  };

  const onOpenModal = (name: string, item: any) => {
    setSelectedItem(item);
    setSelectedModal(name);
  };
  const onCloseModal = () => {
    setSelectedModal('');
  };

  //　登録後の処理
  const reactToCreation = (apiResponseMsg: string, toastTitle: string, toastMsg: string) => {
    var toastDescription: string =
      apiResponseMsg === 'NO_ERROR' ? `${toastMsg}` : `${toastTitle}に失敗しました。`;
    var toastStatus: AlertStatus = apiResponseMsg === 'NO_ERROR' ? 'success' : 'error';
    setTimeout(() => {
      displayToast(toastTitle, toastDescription, toastStatus);
      onCloseModal();
    }, 1000);
  };

  // 登録処理
  const handlelessonFeeOptionCreation = (values: any) => {
    values.teacher_id = teacher_id;
    values.year_month = year_month;
    if (values.tax_rate === '10') {
      values.total_amount =
        values.unit_price * values.item_count +
        (values.unit_price * values.item_count * values.tax_rate) / 100;
    } else {
      values.total_amount =
        (values.unit_price * values.item_count * (100 + parseInt(values.tax_rate))) / 100;
    }
    values.total_amount = Math.floor(values.total_amount);
    values.total_option_fee = fees.total_option_fee + values.total_amount;
    values.billing_amount = fees.total_lesson_fee + fees?.total_option_fee + values.total_amount;
    const apiResponse = requestServerApi<any>(() =>
      serverApi.organizationsControllerCreateLessonFee(teacher_id, values).catch((e) =>
        apiErrorHandler(e, {
          componentName: 'fee',
          functionName: 'handlelessonFeeOptionCreation',
        }),
      ),
    );
    apiResponse.then((data) => {
      reactToCreation(data.data.message, 'オプション', 'オプションを追加しました。');
      onCloseModal();
      setRefreshFlg(true);
    });
  };

  // 登録処理
  const handlelessonFeeHistoryCreation = () => {
    const apiResponse = requestServerApi<any>(() =>
      serverApi.organizationsControllerCreateLessonFeeHistory(teacher_id, fees).catch((e) =>
        apiErrorHandler(e, {
          componentName: 'fee',
          functionName: serverApi.organizationsControllerCreateLessonFeeHistory.name,
        }),
      ),
    );
    apiResponse.then((data) => {
      reactToCreation(data.data?.message, '請求書送信', '請求書送信に成功しました。');
      onCloseModal();
      setRefreshFlg(true);
    });
  };

  // 有料オプションを削除
  const deleteFeeOption = (branch_number: number) => {
    const apiResponse = requestServerApi<any>(() =>
      serverApi.organizationsControllerDeleteLessonFeeOption(branch_number).catch((e) =>
        apiErrorHandler(e, {
          componentName: 'fee',
          functionName: serverApi.organizationsControllerDeleteLessonFeeOption.name,
        }),
      ),
    );
    apiResponse.then((data) => {
      reactToUpdation(data.data?.message, 'レッスン料削除');
      onCloseModal();
      setRefreshFlg(true);
    });
  };

  const confirmedLessonFeeOption = (finalFee: number) => {
    const apiResponse = requestServerApi<any>(() =>
      serverApi
        .organizationsControllerUpdateBillingStatus(
          teacher_id,
          year_month,
          BILLED_FLG.CONFIRMED,
          finalFee,
        )
        .catch((e) =>
          apiErrorHandler(e, {
            componentName: 'fee',
            functionName: serverApi.organizationsControllerUpdateBillingStatus.name,
          }),
        ),
    );
    apiResponse.then((data) => {
      reactToUpdation(data.data.message, '請求書ステータス更新');
      onCloseModal();
      setRefreshFlg(true);
    });
  };

  useEffect(() => {
    getTeacherLessonFee();
    setRefreshFlg(false);
  }, [refreshFlg == true]);

  return (
    <>
      {/* 有料オプションの削除 */}
      {'deleteLessonOptionModal' === selectedModal && (
        <DeleteLessonOptionModal
          onClose={onCloseModal}
          selectedItem={selectedItem}
          deleteFeeOption={deleteFeeOption}
        />
      )}

      {'createLessonFeeOptionModal' === selectedModal && (
        <CreateLessonFeeOptionModal
          onClose={onCloseModal}
          handlelessonFeeOptionCreation={handlelessonFeeOptionCreation}
        />
      )}

      {'confirmedLessonFeeOptionModal' === selectedModal && (
        <ConfirmedLessonFeeOptionModal
          onClose={onCloseModal}
          handlelessonFeeHistoryCreation={handlelessonFeeHistoryCreation}
          confirmedLessonFeeOption={confirmedLessonFeeOption}
          finalFee={finalFee}
        />
      )}

      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="lg" pb="1rem" mt={5}>
          月別レッスン料詳細
        </Heading>
      </Flex>
      <Grid templateColumns="repeat(5, 1fr)" gap={1}>
        <GridItem colSpan={2} mt={3}>
          <Stack justifyContent="center" pl={1} pr={1} spacing={2}>
            <Text fontSize="lg" fontWeight={'bold'}>
              レッスン年月
            </Text>
            <Text fontSize="md">{getYearMonthFormattedDate(fees?.year_month)}</Text>
            <Text fontSize="lg" fontWeight={'bold'}>
              請求ステータス
            </Text>

            {/* FIXME デザイン崩れ */}
            {fees?.billed_flg == 0 && fees?.year_month >= currentYearMonth ? (
              <Badge colorScheme="teal" width="5.25rem" p={'5px'}>
                稼動中
              </Badge>
            ) : null}
            {fees?.billed_flg == BILLED_FLG.DEFAULT && fees?.year_month < currentYearMonth ? (
              <Badge colorScheme="teal" width="5.25rem" p={'5px'}>
                未請求
              </Badge>
            ) : null}

            {fees?.billed_flg == BILLED_FLG.CONFIRMED ? (
              <Badge colorScheme="yellow" width="5.25rem" p={'5px'}>
                請求送信済
              </Badge>
            ) : null}
            {fees?.billed_flg == BILLED_FLG.APPROVED ? (
              <Badge textAlign="center" colorScheme="blue" width="5.25rem">
                請求確定
              </Badge>
            ) : null}

            {fees?.billed_flg == BILLED_FLG.DENIED ? (
              <Badge colorScheme="red" width="5.25rem" p={'5px'}>
                差戻
              </Badge>
            ) : null}

            <Text fontSize="lg" fontWeight={'bold'}>
              レッスン実施数
            </Text>
            <Text fontSize="md"> {fees?.total_lesson_couont} 回</Text>
            {feeDetail?.length > 0 ? (
              <Text fontSize="lg" fontWeight={'bold'}>
                内訳
              </Text>
            ) : null}
            {feeDetail?.map((detail: any, count: number) => (
              <Flex>
                <Box>
                  {detail.total_amount}円 (税率 {detail.tax_rate}%)
                </Box>
                <Box style={{ marginLeft: '15px' }}>：{detail.lesson_count}回</Box>
              </Flex>
            ))}
            <Text fontSize="lg" fontWeight={'bold'}>
              レッスン請求金額(税込)
            </Text>
            <Text fontSize="md">{fees?.total_lesson_fee}円 </Text>
          </Stack>
        </GridItem>
        <GridItem colSpan={3} mt={3}>
          <Grid templateColumns="repeat(5, 1fr)">
            <GridItem width={40}>
              <Text fontSize="lg" fontWeight={'bold'}>
                オプション請求
              </Text>
            </GridItem>
            <GridItem marginLeft={'113px'}>
              <Box marginLeft={'16rem'}>
                <Button
                  colorScheme="blue"
                  size="md"
                  variant="outline"
                  onClick={() => onOpenModal('createLessonFeeOptionModal', '')}
                  disabled={buttonstate}
                >
                  項目追加
                </Button>
              </Box>
            </GridItem>
          </Grid>
          <div
            style={{
              overflow: 'auto',
              border: '1px solid',
              borderRadius: '10px',
              marginTop: '10px',
            }}
          >
            <Table variant="simple" ref={tableRef}>
              <Thead className="Options-TableHead">
                <Tr>
                  <Th>項目</Th>
                  <Th>単価</Th>
                  <Th>数量</Th>
                  <Th>税率</Th>
                  <Th>金額</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody className="Options-TBody">
                {fees?.t_teacher_lesson_fee_option?.map((option: any) => (
                  <Tr key={Math.random()}>
                    <Td>{option.item_name}</Td>
                    <Td>{option.unit_price}</Td>
                    <Td>{option.item_count}</Td>
                    <Td>{option.tax_rate}</Td>
                    <Td>{option.total_amount}</Td>
                    <Td>
                      <Button
                        colorScheme="red"
                        size="sm"
                        onClick={() => onOpenModal('deleteLessonOptionModal', option)}
                        disabled={buttonstate}
                      >
                        削除
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </GridItem>
      </Grid>
      {/* FIXME 位置がずれている */}
      <Grid templateColumns="repeat(5, 1fr)" mt={'3'} pl="480px">
        <GridItem rowSpan={1} mt={3} width={200}>
          <Text fontSize="lg" fontWeight={'bold'}>
            請求金額合計(税込)
          </Text>
          <Text fontSize="md">{fees?.billing_amount}円</Text>
        </GridItem>
        <GridItem>
          <Button
            colorScheme="blue"
            size="lg"
            variant="solid"
            width={40}
            onClick={() => onOpenModal('confirmedLessonFeeOptionModal', '')}
            disabled={buttonstate}
          >
            請求書送信
          </Button>
        </GridItem>
      </Grid>
    </>
  );
}

export default Index;
