// HACK コンポーネントではない、Util

// 日付を調整する
export const getFormattedDate = (datetimeStr: string) => {
  if (!datetimeStr) return '';
  let datetime = new Date(datetimeStr);
  let adjustedDate = getAdjustedDate(datetime);

  return adjustedDate;
};
const getAdjustedDate = (datetime: Date) => {
  let adjustedDate =
    datetime.getFullYear() +
    '/' +
    ('00' + (datetime.getMonth() + 1)).slice(-2) +
    '/' +
    ('00' + datetime.getDate()).slice(-2);

  return adjustedDate;
};

export const getFormattedTime = (datetimeStr: string) => {
  if (!datetimeStr) return '';
  let datetime = new Date(datetimeStr);
  let adjustedtime = getAdjustedTime(datetime);

  return adjustedtime;
};

const getAdjustedTime = (datetime: Date) => {
  let adjustedTime =
    ('00' + datetime.getHours()).slice(-2) + ':' + ('00' + datetime.getMinutes()).slice(-2);
  return adjustedTime;
};

export const getFormattedNumberWithCommas = (val: number) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getYearMonthFormattedDate = (dateStr: string) => {
  var adjustedDate: string =
    dateStr !== undefined ? `${dateStr.substring(0, 4)}年${dateStr.substring(4, 6)}月` : '';
  return adjustedDate;
};

// 日付を調整する
// YYYY/MM/DD
export const getFormattedDateWithSlash = (dateStr: string) => {
  var adjustedDate: string =
    dateStr !== undefined
      ? `${dateStr.substring(0, 4)}/${dateStr.substring(5, 7)}/${dateStr.substring(8, 10)}`
      : '';
  return adjustedDate;
};

// レッスンデータを整形する
export const FormattedDateTimeToData = (data: any) => {
  // // レッスン日、時間を表示用に整形
  data['date'] = getFormattedDatetimeToDate(data?.start_datetime);

  return data;
};

export const getFormattedDatetimeToDate = (date: Date) => {
  var year_str = String(date.getFullYear());
  //月だけ+1すること
  var month_str = ('00' + (1 + date.getMonth())).slice(-2);
  var day_str = ('00' + date.getDate()).slice(-2);

  var format_str = 'YYYY-MM-DD';
  format_str = format_str.replace(/YYYY/g, year_str);
  format_str = format_str.replace(/MM/g, month_str);
  format_str = format_str.replace(/DD/g, day_str);

  return format_str;
};
export const convertLocalToUTCDate = (date: Date) => {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return newDate.toISOString();
};

export const getFormattedDatetime = (datetimeStr: string) => {
  if (!datetimeStr) return '';
  let datetime = new Date(datetimeStr);
  let adjustedDate = getAdjustedDate(datetime);

  let adjustedtime = getAdjustedTime(datetime);

  return adjustedDate + ' ' + adjustedtime;
};
export const getHoursAndMinutes = (totalMinutes: number) => {
  if (totalMinutes < 60) {
    return `00 : ${totalMinutes}`;
  }
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const time = `${hours} :` + (minutes > 10 ? `${minutes}` : ` 0${minutes}`);
  return time;
};

export const getFormattedMonthDatetime = (datetimeString: string) => {
  try {
    const date = new Date(datetimeString);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid datetime string');
    }
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Intl.DateTimeFormat('ja-JP', options).format(date);
  } catch (error) {
    console.error('Error formatting datetime:', error);
    return 'Invalid datetime';
  }
};


